import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
  subheader: {
    marginTop: 8,
    color: 'rgba(0,0,0,.57)',
    fontSize: 12,
  },
});

export default styles;
